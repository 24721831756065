import { useEffect, useState, useRef } from 'react';
import { getFirestore, collection, query, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../Styles/searchStyle.css';

function Search({ onExerciseClick }) {
    const db = getFirestore();
    const auth = getAuth();
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    const exercisesRef = collection(db, 'exercises');
    const playlistsRef = collection(db, `Users/${userId}/playlists`);

    const [exercises, setExercises] = useState([]);
    const [filteredExercises, setFilteredExercises] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [playlists, setPlaylists] = useState([]);
    const [showPlaylists, setShowPlaylists] = useState(null);
    const [totalExercises, setTotalExercises] = useState(0); 

    const dropdownRef = useRef(null); 

    // Fetch all exercises on component mount
    useEffect(() => {
        const fetchAllExercises = async () => {
            const q = query(exercisesRef);
            const querySnapshot = await getDocs(q);

            const allExercises = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setExercises(allExercises);
            setFilteredExercises(allExercises);
            setTotalExercises(allExercises.length);
        };

        const fetchUserPlaylists = async () => {
            if (userId) {
                const q = query(playlistsRef);
                const querySnapshot = await getDocs(q);

                const allPlaylists = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPlaylists(allPlaylists);
            }
        };

        fetchAllExercises();
        fetchUserPlaylists();
    }, [db, userId]);

    // Function to apply search and filter
    const applyFilters = (searchTerm, selectedTags) => {
        let results = exercises;

        if (searchTerm) {
            results = results.filter(exercise =>
                exercise.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        if (selectedTags.length > 0) {
            results = results.filter(exercise =>
                selectedTags.every(tag => exercise.tags && exercise.tags.includes(tag))
            );
        }

        setFilteredExercises(results);
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        const term = event.target.value;
        setSearchTerm(term);
        applyFilters(term, selectedTags);
    };

    // Handle tag filter change
    const handleTagChange = (event) => {
        const tag = event.target.value;
        const newTags = selectedTags.includes(tag)
            ? selectedTags.filter(t => t !== tag)
            : [...selectedTags, tag];
        
        setSelectedTags(newTags);
        applyFilters(searchTerm, newTags);
    };

    // Extract unique tags for filter options
    const extractTags = () => {
        const allTags = exercises.flatMap(exercise => exercise.tags || []);
        return [...new Set(allTags)];
    };

    const handleShowPlaylists = (exerciseId) => {
        setShowPlaylists(exerciseId);
    };

    // Handle adding an exercise to a playlist
    const handleAddToPlaylist = async (playlistId, exerciseId) => {
        const playlistDocRef = doc(db, `Users/${userId}/playlists`, playlistId);

        await updateDoc(playlistDocRef, {
            exercises: arrayUnion(exerciseId)
        });

        setShowPlaylists(null); // Hide playlists after adding
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowPlaylists(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="searchPageContainer">
            <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={`${totalExercises} exercises`}
            />
            <div className="tagFilters">
                {extractTags().map(tag => (
                    <label key={tag}>
                        <input
                            type="checkbox"
                            value={tag}
                            checked={selectedTags.includes(tag)}
                            onChange={handleTagChange}
                        />
                        {tag}
                    </label>
                ))}
            </div>
            <div className="exerciseList">
                {filteredExercises.length > 0 ? (
                    filteredExercises.map((exercise) => (
                        <div key={exercise.id} className="exerciseCard2" >
                            <div className="cardText2" onClick={() => onExerciseClick(exercise)}>
                                <h3>{exercise.name}</h3>
                                <p>
                                    {exercise.tags && exercise.tags.length > 0
                                        ? exercise.tags.join(' • ')
                                        : 'No tags available'}
                                </p>
                            </div>
                            <button className="addToPlaylist" onClick={() => handleShowPlaylists(exercise.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 18h2m2 0h-2m0 0v-2m0 2v2M2 11h18M2 17h12M2 5h18"/></svg>
                            </button>
                            {showPlaylists === exercise.id && (
                                <div className="playlistDropdown" ref={dropdownRef}>
                                    {playlists.length > 0 ? (
                                        playlists.map(playlist => (
                                            <button
                                                key={playlist.id}
                                                onClick={() => handleAddToPlaylist(playlist.id, exercise.id)}
                                            >
                                                {playlist.name}
                                            </button>
                                        ))
                                    ) : (
                                        <p>No playlists available</p>
                                    )}
                                    {/* <button className="closeAddToPlaylist" onClick={() => setShowPlaylists(false)}>X</button> */}
                                </div>
                            )}
                            <svg className="playSearchItem" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24"><path fill="white" d="M8 5.14v14l11-7z"/></svg>
                        </div>
                    ))
                ) : (
                    <p>No exercises found.</p>
                )}
            </div>
        </div>
    );
}

export default Search;
