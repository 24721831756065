import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDoc, arrayUnion, arrayRemove, query, where, collection, getDocs } from 'firebase/firestore';
import '../Styles/exerciseStyle.css';

function Exercise({ exercise, onExerciseChange }) {
    const [isFavorited, setIsFavorited] = useState(false);
    const [relatedExercises, setRelatedExercises] = useState([]);
    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const fetchFavoriteStatus = async () => {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, 'Users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    const favoriteExerciseIds = userData.favorites || [];
                    setIsFavorited(favoriteExerciseIds.includes(exercise.id));
                }
            }
        };

        const fetchRelatedExercises = async () => {
            if (exercise && exercise.tags) {
                const relatedExercisesQuery = query(
                    collection(db, 'exercises'),
                    where('tags', 'array-contains-any', exercise.tags) // Match any tag in the exercise's tags array
                );
                const querySnapshot = await getDocs(relatedExercisesQuery);
                const exercises = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setRelatedExercises(exercises.filter(e => e.id !== exercise.id)); // Exclude the current exercise
            }
        };

        fetchFavoriteStatus();
        fetchRelatedExercises();
    }, [auth, db, exercise.id, exercise.tags]);

    useEffect(() => {
        const updateRecents = async () => {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, 'Users', user.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    let recents = userData.recents || [];

                    recents.push(exercise.id);
                    await updateDoc(userDocRef, { recents: recents });
                }
            }
        };

        // Only call updateRecents if an exercise is provided
        if (exercise) {
            updateRecents();
        }
    }, [auth, db, exercise]);

    if (!exercise) {
        return <div>Loading...</div>;
    }

    const formatInstructions = (instructions) => {
        // Split the instructions by numbers followed by a period and a space
        const lines = instructions.split(/\d+\.\s+/).filter(line => line.trim().length > 0);
        return lines.map((line, index) => (
            <p key={index}>
                {index + 1}. {line}
            </p>
        ));
    };

    const handleFavoriteToggle = async () => {
        const user = auth.currentUser;
        if (user) {
            const userDocRef = doc(db, 'Users', user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const favoriteExerciseIds = userData.favorites || [];
                let updatedFavorites;

                if (isFavorited) {
                    updatedFavorites = favoriteExerciseIds.filter(id => id !== exercise.id);
                } else {
                    updatedFavorites = [...favoriteExerciseIds, exercise.id];
                }

                await updateDoc(userDocRef, { favorites: updatedFavorites });
                setIsFavorited(!isFavorited);
            }
        }
    };

    const handleShuffle = () => {
        if (relatedExercises.length > 0) {
            const randomIndex = Math.floor(Math.random() * relatedExercises.length);
            const randomExercise = relatedExercises[randomIndex];
            onExerciseChange(randomExercise); 
        }
    };

    const handleRelatedExerciseClick = (relatedExercise) => {
        onExerciseChange(relatedExercise);
    };

    return (
        <div className="exerciseContainer">
            <div className="exerciseVideoContainer">
                <video 
                    controls
                    src={exercise.videoUrl}
                >
                </video>
                <div className="controls">
                    <button
                        className={`favoriteButton ${isFavorited ? 'favorited' : ''}`}
                        onClick={handleFavoriteToggle}
                    >
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="36px" 
                            height="36px" 
                            viewBox="0 0 24 24" 
                            fill={isFavorited ? "red" : "none"} 
                            stroke={isFavorited ? "none" : "red"}
                        >
                            <path 
                                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <button onClick={handleShuffle}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 256 256"><path fill="currentColor" d="M237.66 178.34a8 8 0 0 1 0 11.32l-24 24A8 8 0 0 1 200 208v-16a72.15 72.15 0 0 1-57.65-30.14l-41.72-58.4A56.1 56.1 0 0 0 55.06 80H32a8 8 0 0 1 0-16h23.06a72.12 72.12 0 0 1 58.59 30.15l41.72 58.4A56.08 56.08 0 0 0 200 176v-16a8 8 0 0 1 13.66-5.66ZM143 107a8 8 0 0 0 11.16-1.86l1.2-1.67A56.08 56.08 0 0 1 200 80v16a8 8 0 0 0 13.66 5.66l24-24a8 8 0 0 0 0-11.32l-24-24A8 8 0 0 0 200 48v16a72.15 72.15 0 0 0-57.65 30.14l-1.2 1.67A8 8 0 0 0 143 107m-30 42a8 8 0 0 0-11.16 1.86l-1.2 1.67A56.1 56.1 0 0 1 55.06 176H32a8 8 0 0 0 0 16h23.06a72.12 72.12 0 0 0 58.59-30.15l1.2-1.67A8 8 0 0 0 113 149"/></svg>
                    </button>
                </div>
                <div className="relatedVideos">
                    <h2>Related Exercises</h2>
                    {relatedExercises.length > 0 ? (
                        relatedExercises.map(relatedExercise => (
                            <div key={relatedExercise.id} className="exerciseCard2" onClick={() => handleRelatedExerciseClick(relatedExercise)}>
                                <div className="cardText2">
                                    <h3>{relatedExercise.name}</h3>
                                    <p>
                                        {relatedExercise.tags && relatedExercise.tags.length > 0
                                            ? relatedExercise.tags.join(' • ')
                                            : 'No tags available'}
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No related exercises found.</p>
                    )}
                </div>
            </div>

            <div className="exerciseText">
                <h2>{exercise.name}</h2>
                <p>{formatInstructions(exercise.instructions)}</p>
            </div>

            <div className="radial"></div>
            <div className="dots"></div>
        </div>
    );
}

export default Exercise;
