import { useEffect, useState } from 'react';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../Styles/favouritesStyle.css'; // Optional: Include styles if needed

function Favourites({ onExerciseClick }) {
    const db = getFirestore();
    const auth = getAuth();
    const [favourites, setFavourites] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const fetchUserAndFavourites = async () => {
            const currentUser = auth.currentUser;

            if (currentUser) {
                setUser(currentUser);

                try {
                    // Fetch user data
                    const userDocRef = doc(db, 'Users', currentUser.uid);
                    const userDoc = await getDoc(userDocRef);

                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        const favouriteIds = userData.favorites || [];

                        if (favouriteIds.length > 0) {
                            // Fetch favourite exercises based on IDs
                            const exercisesRef = collection(db, 'exercises');
                            const favouriteExercisesPromises = favouriteIds.map(id => doc(exercisesRef, id));
                            const favouriteExercisesDocs = await Promise.all(favouriteExercisesPromises.map(docRef => getDoc(docRef)));

                            const favouriteExercises = favouriteExercisesDocs.map(doc => ({
                                id: doc.id,
                                ...doc.data()
                            }));

                            setFavourites(favouriteExercises);
                        }
                    } else {
                        console.log('User document does not exist');
                    }
                } catch (error) {
                    console.error('Error fetching favourites: ', error);
                }
            } else {
                console.log('No authenticated user found');
            }
        };

        fetchUserAndFavourites();
    }, [db, auth]);

    return (
        <div className="favouritesPageContainer">
            <h2>Favourites</h2>
            <div className="favouritesList">
                {favourites.length > 0 ? (
                    favourites.map((exercise) => (
                        <div key={exercise.id} className="exerciseCard4" onClick={() => onExerciseClick(exercise)}>
                            <div className="cardText2">
                                <h3>{exercise.name}</h3>
                                <p>
                                    {exercise.tags && exercise.tags.length > 0
                                        ? exercise.tags.join(' • ')
                                        : 'No tags available'}
                                </p>
                            </div>

                            <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24"><path fill="white" d="M8 5.14v14l11-7z"/></svg>
                        </div>
                    ))
                ) : (
                    <p>No favourite exercises found.</p>
                )}
            </div>
        </div>
    );
}

export default Favourites;
