import '../Styles/dashStyle.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import Home from './Home';
import Profile from './Profile';
import Search from './Search';
import Favourites from './Favourites';
import Exercise from './Exercise'; 
import Playlists from './Playlists';

function Dashboard() {
    const [activeTab, setActiveTab] = useState('HomeTab');
    const [previousTab, setPreviousTab] = useState(null);
    const [apiExpanded, setApiExpanded] = useState(false);
    const [exercise, setExercise] = useState(null);

    const auth = getAuth();

    const handleSignOut = (e) => {
        try {
            signOut(auth);
            console.log("Sign out successful.")
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleExerciseClick = (exercise) => {
        setExercise(exercise);
        setActiveTab('ExerciseTab'); // Switch to Exercise tab
    };

    const handleExerciseChange = (newExercise) => {
        setExercise(newExercise);
    };

    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="Nav">
                    <h2 className="logoDash">Alegria</h2>
                    <div className="sideBtns">
                        <button className={activeTab === 'HomeTab' ? 'active' : ''} onClick={() => setActiveTab('HomeTab')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 256 256"><path fill="currentColor" d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28m0 190.61c-6.33-6.09-23-24.41-31.27-54.61h62.54c-8.27 30.2-24.94 48.52-31.27 54.61M94.82 156a140.4 140.4 0 0 1 0-56h66.36a140.4 140.4 0 0 1 0 56ZM128 37.39c6.33 6.09 23 24.41 31.27 54.61H96.73C105 61.8 121.67 43.48 128 37.39M169.41 100h46.23a92.1 92.1 0 0 1 0 56h-46.23a152.7 152.7 0 0 0 0-56m43.25-8h-45a129.4 129.4 0 0 0-29.19-55.4A92.25 92.25 0 0 1 212.66 92m-95.12-55.4A129.4 129.4 0 0 0 88.35 92h-45a92.25 92.25 0 0 1 74.19-55.4M40.36 100h46.23a152.7 152.7 0 0 0 0 56H40.36a92.1 92.1 0 0 1 0-56m3 64h45a129.4 129.4 0 0 0 29.19 55.4A92.25 92.25 0 0 1 43.34 164Zm95.12 55.4a129.4 129.4 0 0 0 29.17-55.4h45a92.25 92.25 0 0 1-74.19 55.4Z"/></svg>
                            Home
                        </button>
                        <button className={activeTab === 'searchTab' ? 'active' : ''} onClick={() => setActiveTab('searchTab')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 16 16"><path fill="currentColor" d="m11.271 11.978l3.872 3.873a.5.5 0 0 0 .708 0a.5.5 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532c-2.73-2.73-7.17-2.73-9.898 0s-2.728 7.17 0 9.9a6.96 6.96 0 0 0 4.949 2.05a.5.5 0 0 0 0-1a5.96 5.96 0 0 1-4.242-1.757a6.01 6.01 0 0 1 0-8.486a6.004 6.004 0 0 1 8.484 0a6.01 6.01 0 0 1 0 8.486a.5.5 0 0 0 .034.738"/></svg>
                            Search
                        </button>
                        <button className={activeTab === 'PlaylistsTab' ? 'active' : ''} onClick={() => setActiveTab('PlaylistsTab')}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linejoin="round"><path d="M6 6L3 7.732V4.268z"/><path stroke-linecap="round" d="M3 12h18M10 6h11M3 18h18"/></g></svg>
                            Playlists
                        </button>
                        <button className={activeTab === 'ProfileTab' ? 'active' : ''} onClick={() => setActiveTab('ProfileTab')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 48 48"><g fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"><path d="M24 27a8 8 0 1 0 0-16a8 8 0 0 0 0 16m0-2a6 6 0 1 0 0-12a6 6 0 0 0 0 12"/><path d="M44 24c0 11.046-8.954 20-20 20S4 35.046 4 24S12.954 4 24 4s20 8.954 20 20M33.63 39.21A17.9 17.9 0 0 1 24 42a17.9 17.9 0 0 1-9.831-2.92q-.36-.45-.73-.93A2.14 2.14 0 0 1 13 36.845c0-1.077.774-1.98 1.809-2.131c6.845-1 11.558-.914 18.412.035A2.08 2.08 0 0 1 35 36.818c0 .48-.165.946-.463 1.31q-.461.561-.907 1.082m3.355-2.744c-.16-1.872-1.581-3.434-3.49-3.698c-7.016-.971-11.92-1.064-18.975-.033c-1.92.28-3.335 1.856-3.503 3.733A17.94 17.94 0 0 1 6 24c0-9.941 8.059-18 18-18s18 8.059 18 18a17.94 17.94 0 0 1-5.015 12.466"/></g></svg>
                            Profile
                        </button>
                        
                    </div>
                    <button className="logoutBtn" onClick={handleSignOut}>
                        Logout
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M11 16L7 12M7 12L11 8M7 12H21M16 16V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H13C13.7956 4 14.5587 4.31607 15.1213 4.87868C15.6839 5.44129 16 6.20435 16 7V8" stroke="#090516" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>

                <div className="tabs">
                    {activeTab === 'HomeTab' && (
                        <Home onExerciseClick={handleExerciseClick} />
                    )}
                    {activeTab === "searchTab" && (
                        <Search onExerciseClick={handleExerciseClick} />
                    )}
                    {activeTab === 'ProfileTab' && (
                        <Profile />
                    )}
                    {activeTab === 'ExerciseTab' && exercise && (
                        <Exercise exercise={exercise} onExerciseChange={handleExerciseChange} />
                    )}
                    {activeTab === 'PlaylistsTab' && (
                        <Playlists />
                    )}
                </div>

                
            </div>
        </div>
    )
}

export default Dashboard;