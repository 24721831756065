import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc, serverTimestamp, query, collection, where, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import '../Styles/subPageStyle.css';

function Subscription() {
    const auth = getAuth();
    const [referralCode, setReferralCode] = useState("");
    const [error, setError] = useState("");

    const handleSubscribe = async (priceId) => {
        const user = auth.currentUser;

        if (user) {
            try {
                const response = await fetch('https://yzumtqxkjh.ap-southeast-2.awsapprunner.com/create-checkout-session', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: user.email,
                        priceId,
                    }),
                });

                const data = await response.json();

                if (data.url) {
                    window.open(data.url, '_blank'); // Open Stripe payment in a new tab
                }
            } catch (error) {
                console.error('Error creating checkout session:', error);
            }
        } else {
            alert('Please log in to subscribe.');
        }
    };

    const handleReferralCode = async () => {
        const user = auth.currentUser;
    
        if (user) {
            try {
                // Query Firestore to find a document where the 'code' field matches the entered referral code
                const q = query(collection(db, "referalCodes"), where("code", "==", referralCode));
                const querySnapshot = await getDocs(q);
    
                if (!querySnapshot.empty) {
                    let codeData = null;
    
                    querySnapshot.forEach((doc) => {
                        codeData = doc.data();
                    });
    
                    if (codeData) {
                        // Set a trial period of one month
                        const trialEndsAt = new Date();
                        trialEndsAt.setMonth(trialEndsAt.getMonth() + 1);
    
                        // Check if the user already has a subscription
                        const userDocRef = doc(db, "Users", user.uid);
                        const userDoc = await getDoc(userDocRef);
    
                        if (userDoc.exists() && userDoc.data().subscription) {
                            setError("You have already redeemed a referral code or have an active subscription.");
                            return;
                        }
    
                        // Save the redeemed referral code and trial expiration date in the user's document
                        await setDoc(userDocRef, {
                            subscription: {
                                redeemedCode: referralCode,
                                expiresAt: trialEndsAt,
                            },
                        }, { merge: true });
    
                        alert("Trial activated! You have 1 month of free access. Refresh to access.");
                    } else {
                        setError("Invalid referral code.");
                    }
                } else {
                    setError("Invalid referral code.");
                }
            } catch (error) {
                console.error("Error handling referral code:", error);
                setError("An error occurred. Please try again.");
            }
        } else {
            alert('Please log in to use a referral code.');
        }
    };

    const handleSignOut = (e) => {
        try {
            signOut(auth);
            console.log("Sign out successful.")
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div className="subPage">
            <h2 className="logoSub">Alegria</h2>
            <button className="logout" onClick={handleSignOut}>
                Logout
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M11 16L7 12M7 12L11 8M7 12H21M16 16V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H13C13.7956 4 14.5587 4.31607 15.1213 4.87868C15.6839 5.44129 16 6.20435 16 7V8" stroke="#090516" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
            <div className="subscriptionOptions">
                <div className="subscriptionCard">
                    <h2>Monthly $11.99</h2>
                    <p>Access to the platform and all it's features.</p>
                    <h4>Features:</h4>
                    <ul>
                        <li>Acces to 200+ Exercises</li>
                        <li>Video instruction to each exercise</li>
                        <li>Written instruction to each exercise</li>
                        <li>Save your favourite exercises to quickly access them</li>
                        <li>Roulette feature for related exercises</li>
                        <li>Reuse your most recent exercises</li>
                    </ul>
                    <button onClick={() => handleSubscribe('price_1PrtqLDZzkBAX5MI0EkOw4Xa')}>Subscribe</button>
                </div>

                <div className="subscriptionCard">
                    <h2>8 Week Program $23.99</h2>
                    <p>Access to the platform and all it's features.</p>
                    <h4>Features:</h4>
                    <ul>
                        <li>Acces to 200+ Exercises</li>
                        <li>Video instruction to each exercise</li>
                        <li>Written instruction to each exercise</li>
                        <li>Save your favourite exercises to quickly access them</li>
                        <li>Roulette feature for related exercises</li>
                        <li>Reuse your most recent exercises</li>
                    </ul>
                    <button onClick={() => handleSubscribe('price_1PtNDpDZzkBAX5MIREKqBloR')}>Subscribe</button>
                </div>

                <div className="subscriptionCard">
                    <h2>Yearly $115</h2>
                    <p>Access to the platform and all it's features. Save 10% per year!</p>
                    <h4>Features:</h4>
                    <ul>
                        <li>Acces to 200+ Exercises</li>
                        <li>Video instruction to each exercise</li>
                        <li>Written instruction to each exercise</li>
                        <li>Save your favourite exercises to quickly access them</li>
                        <li>Roulette feature for related exercises</li>
                        <li>Reuse your most recent exercises</li>
                    </ul>
                    <button onClick={() => handleSubscribe('price_1PrtpUDZzkBAX5MIRmdpT7Sd')}>Subscribe</button>
                </div>
            </div>

            <div className="referralSection">
                <input
                    type="text"
                    placeholder="Enter referral code"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                />
                <button onClick={handleReferralCode}>Redeem Code</button>
                {error && <p className="errorText">{error}</p>}
            </div>
        </div>
    );
}

export default Subscription;