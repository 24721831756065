import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../Styles/playlistStyle.css';

function PlaylistDetails({ playlistId, onClose }) {
    const [playlist, setPlaylist] = useState(null);
    const [playlistExercises, setPlaylistExercises] = useState([]);
    const [allExercises, setAllExercises] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredExercises, setFilteredExercises] = useState([]);
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const fetchPlaylist = async () => {
            const user = auth.currentUser;
            if (user) {
                const playlistDocRef = doc(db, `Users/${user.uid}/playlists`, playlistId);
                const playlistDocSnap = await getDoc(playlistDocRef);
                if (playlistDocSnap.exists()) {
                    const playlistData = playlistDocSnap.data();
                    setPlaylist(playlistData);

                    if (playlistData.exercises.length > 0) {
                        const exercisesCollection = collection(db, 'exercises');
                        const playlistExerciseQuery = query(exercisesCollection, where('__name__', 'in', playlistData.exercises));
                        const playlistQuerySnapshot = await getDocs(playlistExerciseQuery);
                        const playlistExerciseList = playlistQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        setPlaylistExercises(playlistExerciseList);
                    }

                    const allExerciseQuerySnapshot = await getDocs(collection(db, 'exercises'));
                    const allExerciseList = allExerciseQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                    setAllExercises(allExerciseList);
                    setFilteredExercises(allExerciseList);
                }
            }
        };

        fetchPlaylist();
    }, [playlistId, auth, db]);

    const handleSearchChange = (e) => {
        const queryStr = e.target.value;
        setSearchQuery(queryStr);

        if (queryStr.trim()) {
            const filtered = allExercises.filter(exercise =>
                exercise.name.toLowerCase().includes(queryStr.toLowerCase())
            );
            setFilteredExercises(filtered);
        } else {
            setFilteredExercises(allExercises);
        }
    };

    const handleAddExercise = async (exerciseId) => {
        if (exerciseId && playlist) {
            const user = auth.currentUser;
            if (user) {
                const playlistDocRef = doc(db, `Users/${user.uid}/playlists`, playlistId);
                const existingExercises = playlist.exercises || [];

                if (!existingExercises.includes(exerciseId)) {
                    const updatedExercises = [...existingExercises, exerciseId];
                    await updateDoc(playlistDocRef, {
                        exercises: updatedExercises
                    });

                    setPlaylist(prev => ({
                        ...prev,
                        exercises: updatedExercises
                    }));

                    const addedExercise = allExercises.find(e => e.id === exerciseId);
                    setPlaylistExercises(prev => [...prev, addedExercise]);
                } else {
                    console.log('Exercise already in playlist');
                }
            }
        }
    };

    const handleRemoveExercise = async (exerciseId) => {
        if (exerciseId && playlist) {
            const user = auth.currentUser;
            if (user) {
                const playlistDocRef = doc(db, `Users/${user.uid}/playlists`, playlistId);
                const existingExercises = playlist.exercises || [];
                const updatedExercises = existingExercises.filter(id => id !== exerciseId);
                await updateDoc(playlistDocRef, {
                    exercises: updatedExercises
                });

                setPlaylist(prev => ({
                    ...prev,
                    exercises: updatedExercises
                }));

                setPlaylistExercises(prev => prev.filter(e => e.id !== exerciseId));
            }
        }
    };

    const handlePlay = () => {
        setIsPlaying(true);
        setCurrentExerciseIndex(0);
    };

    const playExercise = (index) => {
        if (index < playlistExercises.length) {
            setCurrentExerciseIndex(index);
        }
    };

    const handleNextExercise = () => {
        const nextIndex = currentExerciseIndex + 1;
        if (nextIndex < playlistExercises.length) {
            playExercise(nextIndex);
        } else {
            console.log('End of playlist');
            setIsPlaying(false);
            setCurrentExerciseIndex(null);
        }
    };

    if (!playlist) return null;

    const currentExercise = currentExerciseIndex !== null ? playlistExercises[currentExerciseIndex] : null;

    const formatInstructions = (instructions) => {
        // Split the instructions by numbers followed by a period and a space
        const lines = instructions.split(/\d+\.\s+/).filter(line => line.trim().length > 0);
        return lines.map((line, index) => (
            <p key={index}>
                {index + 1}. {line}
            </p>
        ));
    };

    const closeVideo = () => {
        setIsPlaying(false);
        setCurrentExerciseIndex(null);
    }

    const toggleSearchVisibility = () => {
        setIsSearchVisible(prev => !prev);
    }

    const allTags = new Set();
    playlistExercises.forEach(exercise => {
        if (exercise.tags) {
            exercise.tags.forEach(tag => allTags.add(tag));
        }
    });

    return (
        <div className="playlistContentContainer">
            <div className="dots"></div>

            <div className="controlsContainer">
                <button onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30x" viewBox="0 0 24 24"><path fill="currentColor" d="m4 10l-.354.354L3.293 10l.353-.354zm16.5 8a.5.5 0 0 1-1 0zM8.646 15.354l-5-5l.708-.708l5 5zm-5-5.708l5-5l.708.708l-5 5zM4 9.5h10v1H4zM20.5 16v2h-1v-2zM14 9.5a6.5 6.5 0 0 1 6.5 6.5h-1a5.5 5.5 0 0 0-5.5-5.5z"/></svg>
                </button>
            </div>

            <div className="playlistContent playlistDetails">
                <h2>
                    {playlist.name}
                    <button onClick={handlePlay}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 256 256"><path fill="currentColor" d="M240 128a15.74 15.74 0 0 1-7.6 13.51L88.32 229.65a16 16 0 0 1-16.2.3A15.86 15.86 0 0 1 64 216.13V39.87a15.86 15.86 0 0 1 8.12-13.82a16 16 0 0 1 16.2.3l144.08 88.14A15.74 15.74 0 0 1 240 128"/></svg>
                    </button>
                </h2>

                <p className="exerciseCount">{playlistExercises.length} exercises</p>

                <div className="tagContainer">
                    <ul>
                        {Array.from(allTags).map(tag => (
                            <li key={tag}>{tag}</li>
                        ))}
                    </ul>
                </div>

                <button className="toggleSearch" onClick={toggleSearchVisibility}>
                    <div className="addIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path fill="#626262" d="M222 128a6 6 0 0 1-6 6h-82v82a6 6 0 0 1-12 0v-82H40a6 6 0 0 1 0-12h82V40a6 6 0 0 1 12 0v82h82a6 6 0 0 1 6 6"/></svg>
                    </div>
                    Add exercises
                </button>
                
                <ul>
                    {playlistExercises.length > 0 ? (
                        playlistExercises.map((exercise) => (
                            <div className="exerciseInPlaylist" key={exercise.id}>
                                <div className="inPlaylistDetails">
                                    <h3>{exercise.name}</h3>
                                    <p>
                                        {exercise.tags && exercise.tags.length > 0
                                                ? exercise.tags.join(' • ')
                                                : 'No tags available'}
                                    </p>
                                </div>
                                
                                <button onClick={() => handleRemoveExercise(exercise.id)}>Remove</button>
                            </div>
                            
                        ))
                    ) : (
                        <p>No exercises in this playlist.</p>
                    )}
                </ul>
            </div>

            {isPlaying && currentExercise && (
                <div className="videoContainer">
                    <div className="mainVidContent">
                        <button className="exitPlaylistVideo" onClick={closeVideo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30x" viewBox="0 0 24 24"><path fill="currentColor" d="m4 10l-.354.354L3.293 10l.353-.354zm16.5 8a.5.5 0 0 1-1 0zM8.646 15.354l-5-5l.708-.708l5 5zm-5-5.708l5-5l.708.708l-5 5zM4 9.5h10v1H4zM20.5 16v2h-1v-2zM14 9.5a6.5 6.5 0 0 1 6.5 6.5h-1a5.5 5.5 0 0 0-5.5-5.5z"/></svg>
                        </button>
                        <h3>Now Playing: {currentExercise.name}</h3>
                        <video src={currentExercise.videoUrl} controls autoPlay />
                    </div>
                    <p>{formatInstructions(currentExercise.instructions)}</p>
                    <button className="nextExercise" onClick={handleNextExercise}>Next</button>
                </div>
            )}

            {isSearchVisible && (
                <div className="searchContainer">
                    <button className="closeSearch" onClick={toggleSearchVisibility}>X</button>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search for exercises..."
                    />
                    <ul className="searchResults">
                        {filteredExercises.map(exercise => (
                            <li key={exercise.id}>
                                {exercise.name}
                                <button onClick={() => handleAddExercise(exercise.id)}>+</button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default PlaylistDetails;
