import { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../Styles/profileStyle.css';

function Profile() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [subscriptions, setSubscriptions] = useState([]);

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const fetchUserDetails = async () => {
            const user = auth.currentUser;
            if (user) {
                const userDocRef = doc(db, 'Users', user.uid);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    setEmail(userData.email || ""); // Set the email
                    setUsername(userData.username || ""); // Set the username

                    // Fetch active subscriptions
                    const response = await fetch('https://yzumtqxkjh.ap-southeast-2.awsapprunner.com/get-active-subscriptions', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email: userData.email }),
                    });

                    const data = await response.json();
                    console.log(data);
                    setSubscriptions(data);
                } else {
                    console.warn('User document does not exist.');
                }
            } else {
                console.warn('No user is currently logged in.');
            }
        };

        fetchUserDetails();
    }, [auth, db]);

    const handleCancelSubscription = async (subscriptionId) => {
        try {
            const response = await fetch('https://yzumtqxkjh.ap-southeast-2.awsapprunner.com/cancel-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subscriptionId }),
            });

            const data = await response.json();
            if (data.status === 'active' && data.cancel_at_period_end) {
                alert('Subscription will be canceled at the end of the billing period.');
            }
        } catch (error) {
            console.error('Error canceling subscription:', error);
        }
    };

    return (
        <div className="profileContainer">
            <div className="userDetails">
                <h2>{username}</h2>
                <p>Email: {email}</p>
            </div>
            
            <div className="subscriptionsContainer">
                <h3>Subscription</h3>
                {subscriptions.length > 0 ? (
                    subscriptions.map(subscription => (
                        <div key={subscription.id} className="subscriptionItem">
                            <div>
                                <h3>Plan: {subscription.plan.nickname} ${subscription.plan.amount/100}</h3>
                                <p>Status: {subscription.status}</p>
                                <p className="nextBill">Next Billing Date: {new Date(subscription.current_period_end * 1000).toLocaleDateString()}</p>
                            </div> 
                            <button
                                onClick={() => handleCancelSubscription(subscription.id)}
                                disabled={subscription.cancel_at_period_end}
                            >
                                {subscription.cancel_at_period_end ? 'Cancellation Scheduled' : 'Cancel Subscription'}
                            </button>

                            <h3 className="deco">Alegria Workout Roulette</h3>
                        </div>
                    ))
                ) : (
                    <p>No active subscriptions found.</p>
                )}
            </div>
        </div>
    )
};

export default Profile;