import { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../Styles/homeStyle.css';
import Favourites from './Favourites';

function Home({ onExerciseClick }) {
    const [favorites, setFavorites] = useState([]);
    const [randomExercise, setRandomExercise] = useState(null);
    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const fetchFavorites = async () => {
            const user = auth.currentUser;
            if (user) {
                console.log(`User ID: ${user.uid}`);

                const userDocRef = doc(db, 'Users', user.uid);
                const userDocSnap = await getDoc(userDocRef);

                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    const favoriteExerciseIds = userData.recents || [];

                    const recentExerciseIds = favoriteExerciseIds.slice(-3);

                    console.log('User data:', userData);

                    // Fetch the exercise details
                    const exercisePromises = recentExerciseIds.map(async (exerciseId) => {
                        const exerciseDocRef = doc(db, 'exercises', exerciseId);
                        const exerciseDocSnap = await getDoc(exerciseDocRef);
                        if (exerciseDocSnap.exists()) {
                            console.log(`Fetched exercise data for ID ${exerciseId}:`, exerciseDocSnap.data());
                            return exerciseDocSnap.data();
                        } else {
                            console.warn(`Exercise with ID ${exerciseId} not found.`);
                            return null;
                        }
                    });

                    const favoriteExercises = await Promise.all(exercisePromises);
                    const validExercises = favoriteExercises.filter(exercise => exercise !== null);

                    console.log('Valid favorite exercises:', validExercises);
                    setFavorites(favoriteExercises);
                } else {
                    console.warn('User document does not exist.');
                }
            }
        };

        const fetchRandomExercise = async () => {
            const exercisesRef = collection(db, 'exercises');
            const querySnapshot = await getDocs(exercisesRef);
            const allExercises = querySnapshot.docs.map(doc => doc.data());

            if (allExercises.length > 0) {
                // Select a random exercise from the list
                const randomIndex = Math.floor(Math.random() * allExercises.length);
                setRandomExercise(allExercises[randomIndex]);
                console.log('Random exercise: ', randomExercise);
            }
        };

        fetchRandomExercise();
        fetchFavorites();
    }, [auth, db]);

    return (
        <div className="homePage">
        <div className="homePageContainer">
            {randomExercise && (
                <div className="mainVideo" onClick={() => onExerciseClick(randomExercise)}>
                    <div className="videoContainer">
                        <h2>{randomExercise.name}</h2>
                        <p>
                            {randomExercise.tags && randomExercise.tags.length > 0
                                ? randomExercise.tags.join(' • ')
                                : 'No tags available'}
                        </p>
                    </ div>
                    <video controls>
                        <source src={randomExercise.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
            <div className="favourites">
                <h2>Recent</h2>
                <div className="recentRow">
                    {favorites.map((exercise, index) => (
                        <div key={index} className="exerciseCard" onClick={() => onExerciseClick(exercise)}>
                            <div className="cardText">
                                <h3>{exercise.name}</h3>
                                <p>
                                    {exercise.tags && exercise.tags.length > 0
                                        ? exercise.tags.join(' • ')
                                        : 'No tags available'}
                                </p>
                            </div>
                            <h4>Get Fit</h4>
                        </div>
                    ))}
                </div>
            </div>
            <Favourites onExerciseClick={onExerciseClick} />

            <div className="radial"></div>
            <div className="dots"></div>
        </div>
        </div>
    );

};

export default Home;