import './App.css';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import Subscription from './Components/Subscription';
import React, { useEffect, useState } from 'react';
import { auth } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

function App() {
  const [user, setUser] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
       setUser(currentUser);
       if (currentUser) {
            // Check subscription status once the user is logged in
            try {
                const userDocRef = doc(db, "Users", currentUser.uid);
                const userDoc = await getDoc(userDocRef);

                const response = await fetch('https://yzumtqxkjh.ap-southeast-2.awsapprunner.com/check-subscription', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: currentUser.email }),
                });
                
                const result = await response.json();

                if (result.subscribed) {
                    setIsSubscribed(true);
                } else {
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDoc = await getDoc(userDocRef);
                  
                    if (userDoc.exists()) {
                        const userData = userDoc.data();
                        const subscription = userData.subscription;

                        if (subscription && subscription.redeemedCode) {
                            const trialExpiresAt = subscription.expiresAt.toDate();
                            
                            if (new Date() < trialExpiresAt) {
                                setIsSubscribed(true);
                            } else {
                                setIsSubscribed(false);
                            }
                        } else {
                            setIsSubscribed(false);
                        }
                    } else {
                        setIsSubscribed(false);
                    }
                }
            } catch (error) {
                console.error('Error checking subscription status:', error);
            }
      }
      setLoading(false);
  });

  return () => unsubscribe();

  }, []);

  //Check if subscribed

  return (
    <div className="App">
        {user ? (isSubscribed ? <Dashboard /> : <Subscription />) : <Login />}
    </div>
  );
}

export default App;
