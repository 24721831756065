import react, { useState, useEffect } from 'react';
import '../Styles/messageStyle.css';

function Message({ header, body, onClose }) {
    const [isVisible, setIsVisible] = useState(true);

    const handleContinue = () => {
        onClose();
    };

    return (
        <>
            {isVisible && (
                <div className="messageContainer">
                    <div className="imgContainer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 64 64" fill="none">
                            <path d="M32.0013 24.0006V29.3339M32.0013 40.0006H32.0279M13.5266 50.6673H50.4759C54.5826 50.6673 57.1479 46.2219 55.0946 42.6673L36.6199 10.6673C34.5666 7.11262 29.4359 7.11262 27.3826 10.6673L8.90793 42.6673C6.8546 46.2219 9.41993 50.6673 13.5266 50.6673Z" stroke="#F53E05" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className="textContainer">
                        <h2>{header}</h2>
                        <p>{body}</p>
                        <button onClick={handleContinue}>Continue</button>
                    </div>
                </div>
            )}
        </>
    );
}

export default Message;