import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, updateDoc, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import '../Styles/playlistStyle.css';
import PlaylistDetails from './PlaylistDetails'; 

function Playlists() {
    const [playlists, setPlaylists] = useState([]);
    const [newPlaylistName, setNewPlaylistName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlaylistId, setSelectedPlaylistId] = useState(null);

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        const fetchPlaylists = async () => {
            const user = auth.currentUser;
            if (user) {
                const playlistsCollectionRef = collection(db, `Users/${user.uid}/playlists`);
                const querySnapshot = await getDocs(playlistsCollectionRef);
                const playlistsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPlaylists(playlistsData);
            }
        };

        fetchPlaylists();
    }, [auth, db]);

    const handleCreatePlaylist = async () => {
        const user = auth.currentUser;
        if (user && newPlaylistName.trim()) {
            const newPlaylist = {
                name: newPlaylistName.trim(),
                exercises: []
            };

            const playlistsCollectionRef = collection(db, `Users/${user.uid}/playlists`);
            const playlistDocRef = await addDoc(playlistsCollectionRef, newPlaylist);
            setPlaylists([...playlists, { id: playlistDocRef.id, ...newPlaylist }]);
            setNewPlaylistName('');
            setIsModalOpen(false);
        }
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const openPlaylistDetails = (playlistId) => {
        setSelectedPlaylistId(playlistId);
    };

    const closePlaylistDetails = () => {
        setSelectedPlaylistId(null);
    };

    return (
        <div className="playlistContainer">
            <div className="playlistHeader">
                <h2>Your Playlists</h2>
                <button onClick={openModal} className="createPlaylistButton">+</button>
            </div>
            
           
            <div className="playlists">
                {playlists.map((playlist) => (
                    <div key={playlist.id} className="playlistCard" onClick={() => openPlaylistDetails(playlist.id)}>
                        <h3>{playlist.name}</h3>
                        <p>{playlist.exercises.length} exercises</p>
                    </div>
                ))}
            </div>

            {isModalOpen && (
                <div className="modalBackdrop">
                    <div className="modalContent">
                        <h2>Create New Playlist</h2>
                        <input
                            type="text"
                            value={newPlaylistName}
                            onChange={(e) => setNewPlaylistName(e.target.value)}
                            placeholder="Enter playlist name"
                        />
                        <button onClick={handleCreatePlaylist}>Create Playlist</button>
                        <button onClick={closeModal}>Cancel</button>
                    </div>
                </div>
            )}

            {selectedPlaylistId && (
                <PlaylistDetails 
                    playlistId={selectedPlaylistId}
                    onClose={closePlaylistDetails}
                />
            )}
        </div>
    );
}

export default Playlists;
