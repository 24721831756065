import '../Styles/loginStyle.css';
import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '../firebase';
import Message from './Message';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const auth = getAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email).then(data => {
            alert("Check your email.")
        }).catch(err => {
            alert(err.code)
        })
    };

    return (
        <div className="forgotPasswordContainer">
            <h2>Forgot password</h2>
            <form>
                <input type="email" placeholder="Your accounts email" onChange={(e) => setEmail(e.target.value)} />
                <button onClick={handleSubmit}>Submit</button>
            </form>

        </div>
    )
}

export default ForgotPassword;

